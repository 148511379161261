body {
  margin: 0px;
/*  height: 100%;
  background-image: url("../public/images/login.jpg") ;
	background-size: 100% 100% ; */

  background: none repeat scroll 0% 0% #ebedee;
}

html {
  height: 100%;
}

.bgColor{
  height: 100%;
  
}

.progheaderInfo{
  height: 2.4rem; 
  border-radius: 20px 20px 0px 0px;
  text-align: center;
  color: black;
  font-size: 1.4rem;
  font-weight: bold;
}

.kuntosaliclass{ background: rgb(224, 61, 61); }
.juoksuclass{ background: rgb(235, 132, 49); }
.pyorclass{ background: rgb(56 143 7); }
.hiihtoclass{ background: rgb(137 153 161); }
.stretchclass{ background: rgb(21 186 253); }
.muuclass{ background: rgb(238, 100, 215); }

.loginform{
  position: absolute;
  transform: translate(-50%, 0%);
  left: 50%;
  top: 20%;
  width: 400px;
  padding: 5px 20px 13px 20px;
  border-radius: 10px;
  background: #fff;
  background: -moz-linear-gradient(#fff, #999);
  background: -webkit-linear-gradient(#fff, #999);
  background: -o-linear-gradient(#fff, #999);
}
/*#react{
   width:100%;
   height:100%;
 }*/

 .loginbtn{
   float:left;
 }

.backtologin{
  float:right;
  margin-right: 34px;
 }
 .newusertxt{
  color:blue;
  float:right;
  margin-right: 34px;
}

#mainDiv{
  position: relative;
  width: 100%;
}

#TopContent{
  height: 3.87rem;
  position: fixed;
  left: 0rem;
  top: 0rem;
  width: inherit;
  z-index: 2;
  text-align: center;
  border-bottom: 2px solid black;
  background: rgb(158,197,198);
  background: linear-gradient(90deg, rgba(158,197,198,1) 11%, rgba(83,207,207,1) 46%, rgba(156,188,191,1) 88%);
}

.posLeft{
  table-layout: fixed;
  width: 100%;
}

.programDataTallenna{
  float:left;
}
.programDataOther{
  float: right;
  display: block;
}
.pohbtn{
  display: block;
}
.btnyoutube{
  display: block;
}
.btnwidth{
  width: 6.7rem;
  min-width: 6.7rem;
}

.mainBody{
  
  position: absolute;
  top: 4rem;
  left:0rem;
  width: inherit;
  height: 59rem;
  z-index: 1;
}
.mainBodySm{

  position: absolute;
  top: 4rem;
  left:0rem;
  width: inherit;
  height: 77rem;
  z-index: 1;
}

table {
    border-collapse: collapse;
}

#weightT td, #weightT th {
    border: 1px solid #999;
    padding: 0.5rem;
    text-align: left;
}


.menuOption{
  float:left;
}
#nav li {
  margin: 0 5px;
  padding: 0 0 8px;
  float: left;
  position: relative;
  list-style: none;
}

#nav a {
  font-weight: bold;
  color:white;
  text-decoration: none;
  display: block;
  padding: 16px 20px;
  margin: 0;
  border-radius: 1.6rem;
  -webkit-border-radius: 1.6em;
  -moz-border-radius: 1.6em;
  text-shadow: 0 1px 1px rgba(0,0,0, .3);
}

.pointer{
  cursor: pointer;
}

.errorText{
  color:red;
}

#nav{
  margin: 0;
  padding: 7px 6px 0;
  float:left;
  line-height: 100%;
}

#nav .current a, #nav li:hover > a {
  background: lightgrey;;
  color: #444;
  border-top: solid 1px #f8f8f8;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0, .2);
  -moz-box-shadow: 0 1px 1px rgba(0,0,0, .2);
  box-shadow: 0 1px 1px rgba(0,0,0, .2);
  text-shadow: 0 1px 0 rgba(255,255,255, 1);
}




.menuOption:hover{ 
  cursor: pointer;
  background: lightblue;
 }

.bordertop{
  border-top:none !important;
}

.borderbottom{
  border-bottom:none !important;
}

.firstcellsize{
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  height: 1.2rem;  
  padding: 0px;
  width: 9%;/*4rem;*/
 /* min-width: 4rem;*/
  background-color: #fff;
  color: rgba(0, 0, 0, 0.64);
}

.cellsize{
  border-style: solid;
  border-width: 0 0 1px 1px;
  border-color: rgba(0, 0, 0, 0.12);
  height: 1.2rem;
  padding: 0px;
   /* width: 10rem;*/
 /* min-width: 10rem;*/
  background-color: #fafafa;
}

.headercell{
  height: 2.2rem;
  width: 13%;/*10rem;*/
/*  min-width: 10rem;*/
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  background-color: #fff;
}

.field {
  width: 90%;
}

.field2 {
  width: 3rem;
}
.talImages{
  margin-right:0.4rem
}

.journeyInfoDiv{
  display:inline-block;
  width: 33rem;  
  font-weight: bold;
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-radius: 0px 15px 0px 0px
}

.mapDiv > div {
  height: 100% !important;
  width: 30% !important;
}

.journeyInfo1{
  float: left;
  width: 20rem;
}

.journeyInfo2{
  float: left;
}

.mapHolderDiv{
  height: 30rem;
}
.finishMargin{
  margin-left: 3.91rem;
}
.finishMargin2{
  margin-left: 2rem;
}
.finishMargin3{
  margin-left: 1.77rem;
}
.timesSelect{
  height: 1.5rem;
}
.lajiSelect{
  margin-right:3.12rem;
}

#pname{
	margin: 1.4rem 2em 0rem 1rem;
	float: right;
	text-align: center;
}

.mainHeader{
  float:left;
/* 	position: absolute;
 top: 0.7rem;
  left: 50%;
  transform: translate(-50%, -0%);*/
}
.mainHeaderSm{
  float:right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.logout{
	float: right;
	margin: 1rem 2rem 0rem 0rem;
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
  padding: 2px 8px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 18px;
}

#weightT{
 /* width: 20rem;*/
  margin: auto;
}
.scheduletr{
  height: 3rem;
  /*width: 80%; */
  min-height: 3rem;
}
.dayN{
  margin: auto;
  text-align: left;
  text-indent: 0.7rem;
  font-size: 1.3rem;
}
.dayName{
  margin: auto;
  text-align: left;
  text-indent: 0.7rem;
  font-size: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.timetd{
  text-align: center;
  margin-top: 0px;
}
#links{
  width: 7rem;
  margin: auto;
  margin-top: 0.3rem;
}







.parentT{
  text-align: center;
  margin: auto;
}

.parent{
  
  width:50rem;
  overflow-x: hidden; 
  float:left;



  position: absolute;
 /* top:3rem;
  bottom: 0;*/
  left: 0;
  right: 0;
  
  margin: auto;

}
.child{
  width:1000px;
  float:left;
  font-size:15px;
  font-family:arial;
  padding:10px;
  cursor: pointer;
}

.lg{
  background-color: lightgrey;
}

.wdtd{
  border-radius: 0.6rem;
  border:1px solid black;
}

.resT{
  border-collapse: separate;
  border-spacing: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -0.5rem;
  text-align: center;
  width: 60rem;
  }

.resT2{
  border-collapse: separate;
  border-spacing: 5px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 40rem;
  }

.wcn{
  width: 1.3rem;
  margin-block: 0px;
}
.dtro{
  background: ghostwhite;
}
.tbr{
  height:3rem;
}

.md{
  width:100%;
  height: 7rem;
}
.weekCell{
  width: 3rem;
  border: 1px solid black;
  margin: 1rem;
  padding: 1rem;
  border-radius: 2rem;
}

.tdiv{
  width: 40rem;
  overflow: auto;
}










.mainBodyHeader{

  height: 25px;
  margin: 1rem 0rem 1rem 0rem;
  z-index: -1;
  /*position: fixed;*/
  text-align: center;
  width: 100%;
 /* border-bottom: 1px solid #f3f3f3;
*/
}

.mainBodyHeader h2{
  margin: 1rem 0rem 1rem 1rem;
}
.inputInfo{
  float:left;
  margin-left:1rem;
}

.weightInfoDiv{
  width: 100%;
  text-align: center;
  margin: auto;
}

.weightDiv{
  display: inline-block;
  margin: auto;
}

.recharts-wrapper{
  top:2rem;
}

.wLabelLeft{
  margin-left: 0.6rem;
}

.wLabelRight{
  margin-right:0.4rem;
}

.wSelectRight{
  margin-right: 2rem;
}
.chartDiv{
  font-family: sans-serif;
  text-align: center;
  display: inline-block;
  margin-left: 2rem;
  vertical-align: top;
}
.weightIput{
  width: 2.5rem;
  margin: 0rem 2rem 0rem 1rem;
}

#addR{
  margin: auto;
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;
}

.tCent{
  text-align:center !important;
}

.error{
  color: red;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.modalDialog {
  position: fixed;
  font-family: Arial, Helvetica, sans-serif;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
  z-index: 99999;
  opacity:0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;
}

.modalDialog:target {
  opacity:1;
  pointer-events: auto;
}

.react-calendar__navigation__label{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.modalDialog > div {
  width: 400px;
  position: relative;
  margin: 10% auto;
  padding: 5px 20px 13px 20px;
  border-radius: 10px;
  background: #fff;
  background: -moz-linear-gradient(#fff, #999);
  background: -webkit-linear-gradient(#fff, #999);
  background: -o-linear-gradient(#fff, #999);
}

.modalYouTube > div {
  width: 600px !important;
  position: relative;
  margin: 10% auto;
  padding: 15px 15px 15px 15px !important;
  border-radius: 10px;
  background: #fff;
  background: -moz-linear-gradient(#fff, #999);
  background: -webkit-linear-gradient(#fff, #999);
  background: -o-linear-gradient(#fff, #999);
}

.close {
  background: #606061;
  color: #FFFFFF;
  line-height: 25px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: -10px;
  width: 24px;
  text-decoration: none;
  font-weight: bold;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -moz-box-shadow: 1px 1px 3px #000;
  -webkit-box-shadow: 1px 1px 3px #000;
  box-shadow: 1px 1px 3px #000;
}

.close:hover { background: #00d9ff; }

.addBtn span {
   color: black; 
   font-size: 14px; 
   font-family: 'Open Sans', sans-serif; 
   text-transform: none; 
   vertical-align: baseline; 
}
.addBtn{   
  text-decoration: none;
  border: 1px solid #EEE;
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 14px;
  background-color: gainsboro;
  box-shadow: 1px 1px 2px #888;
  padding-top: 4px;
  padding-bottom: 4px;
  box-sizing: border-box;
  vertical-align: baseline;
}

.addBtn:hover {
  margin-top: 0px;
  -moz-box-shadow: 0px 0px 4px 1px grey;
  -webkit-box-shadow: 0px 0px 4px 1px grey;
  box-shadow: 0px 0px 4px 1px  grey;
}

.react-calendar__navigation{
  display:flex;
}

#calendarDiv{
 /* width: 22rem;*/
}
#calendarDivSm{
  float: left;
  width: 47%;
  margin-left: 1%;
}

#rightDivs{
  float: right;
  width: 22%;
  margin-right: 3%;
}
#rightDivsSm{
  float:left;
  width: 100%;
}

#scheduler{ 
  width: 70%;
  float: left;
  margin-left: 3%;
  margin-right: 2%;
  }
#schedulerSm{
  width: 98%;
  float: left;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1rem;
}

#stickyDiv{
  /*width: 22rem;*/
  margin-top: 1.3rem;
}
#stickyDivSm{
  float: left;
  width: 49%;
  margin-left: 2%;
  margin-right: 1%;
}

.mainContent{
  width: 100%;
  margin: auto;
}

.wInc{
  color:green;
}
.wDec{
  color:red;
}

.programcontainer{
      /* width: 500px; */
    /* height: 30rem; */
    /* overflow-x: auto; */
    /* overflow-y: hidden; */
    /* white-space: nowrap; */
    /* margin: auto; */
    float: left;
    margin-right: -3000000px;
}

.progsdiv{
      /* width: 800px; */
    /* overflow: auto; */
    /* height: 500px; */
    /* margin: 0 auto; */
    /* white-space: nowrap; */
 /*   width: 1353px;
    height: 32rem;
    overflow: auto;*/
    /* border: 1px solid black; */
    width: 60rem;
    margin: 0 auto;

}




.react-calendar {
 /* width: 350px; */
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.progbox2{
  height: inherit;
}

.progbox {
  width: 100%;
  border: 1px solid #fff;
  height: 100%;
  overflow: hidden;
  position: absolute;
  font-size: 0.75rem;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  user-select: none;
  border-radius: 4px;
  letter-spacing: 0.03333em;
  background-clip: padding-box;
}
.textInfo {
  height: auto;
  color: #fff;
  display: flex;
  padding: 4px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.textCont {
  width: 100%;
}

.overflowtime{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text1 {
  overflow: hidden;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text2 {
  overflow: hidden;
  line-height: 1;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}


.progcontainer {
  position: relative;
  height: 2.92rem;
}

.progcontainer2 {
  position: relative;
  height: 1.45rem;
}

.progcontainer3 {
  position: relative;
  height: 1.45rem;
  top: 0.73rem;
}

/*
.progcontainer:hover {
  cursor: pointer;
}*/

.progFocus:hover{
  outline: 1px solid black;
  position: relative;
  z-index:999;
  cursor: pointer;
}

.schedulertablehover:hover{ 
  cursor: pointer;
  background: lightblue;
 }


 
.datespan{ margin: 0px 0px 0px 0px;}
.saveProg{ margin-right: 0.6rem;  }

.btnbmtmar{
  margin-bottom: 0.25rem;
}
.noteTitle{
  position: relative;
  top: 0.8rem;
  color: black;
  font-size: 1.2rem;
}

.program-note{

  margin-bottom:1rem;
  height: 29rem;
  display: inline-block;
  width: 22rem;
  color: black;
  background: rgb(246,250,249);
  border-radius: 0px 0px 10px 10px;
  border: 0px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.4);
  /* overflow: hidden; */
  /* opacity: 1; */
  text-shadow: 0px 0px 0px black;
  padding: 1px;
  /* margin: auto; */
  /* float: left; */
}
.sticky-note { 
  height: 29rem;
 /* width: 22rem;*/
  width: 100%;
  color: black;
  background: rgb(246,250,249);
  border-radius: 10px;
  border: 0px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.4);
  overflow: hidden;
  opacity: 1;
  text-shadow: 0px 0px 0px black;
  padding: 1px;
}

.sticky-noteSm { 
  height: 16.5rem;
  /*width: 27rem;*/
  width: 100%;
  color: black;
  background: rgb(246,250,249);
  border-radius: 10px;
  border: 0px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.4);
  overflow: hidden;
  opacity: 1;
  text-shadow: 0px 0px 0px black;
  padding: 1px;
}
.contents { background: white; margin: 20px; outline: none; height:21rem; margin-bottom: 0px; overflow: auto;}
.contentsSm { background: white; margin: 20px; outline: none; height:8.6rem; margin-bottom: 0px; overflow: auto;}
.handle { 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: rgb(213,245,44);
  background: linear-gradient(90deg, rgba(213,245,44,1) 0%, rgba(152,209,49,1) 45%, rgba(213,245,44,1) 100%);
  border-radius: 8px 8px 0px 0px; 
  height: 3rem; 
  text-align: center;
}

.handle2 { 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: rgb(213,245,44);
  background: linear-gradient(90deg, rgba(213,245,44,1) 0%, rgba(152,209,49,1) 45%, rgba(213,245,44,1) 100%);
  height: 3rem; 
  text-align: center;
}

.smtrash{
  margin-right:5px;
}

.programData{
  margin: 9px 0px 0px 21px;
  float: left;
}

.programDataBtns{
  float: right;
  margin: 6px 20px 0px 0px;
}

.programDataBtnsResult{
  float: right;
  margin: 9px 20px 0px 0px;
}

.programDataBtns2{
  float: right;
  margin: 22px 20px 0px 0px;
}

.programResultBtns{
  text-align: center;
  margin-top: 22px;
}

.navbar {
 
  width: 3rem;
  display: block;
  padding: 8px 10px;
}

.navbar div {
  height: 5px !important;
  background: #fff;
  margin: 7px 0px 7px 0px;
  border-radius: 1.6rem;
  -webkit-border-radius: 1.6em;
  -moz-border-radius: 1.6em;
}


.dropdown {
  float:left;
}


.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  border-bottom: 1px solid lightgrey;
}
.dropdown-content a:hover {background-color:lightblue;}
.dropdown:hover .dropdown-content {display: block;}
.dropdown:hover .navbar {background-color: lightblue;}

.passbottom {
  margin-bottom: 0px !important;
}

.password-strength-meter {
  text-align: left;
}

.password-strength-meter-progress {
  -webkit-appearance: none;
  appearance: none;
  width: 22.9em;
  height: 8px;
}

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 5px;
}

.password-strength-meter-label {
  font-size: 14px;
}

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Heikko::-webkit-progress-value {
  background-color: #F25F5C;
}
.strength-Kohtalainen::-webkit-progress-value {
  background-color: #FFE066;
}
.strength-Hyvä::-webkit-progress-value {
  background-color: #247BA0;
}
.strength-Vahva::-webkit-progress-value {
  background-color: #00c030;
}


.partcontent::after{
  clear:both;
}

.partcontent::before{
  display: table;
  content: " ";
}

.partcontent{

 /* transform: translate(-50%, 0%);
  left: 50%;
  top: 20%;
  max-width: 100%;*/
  margin-left:auto;
  margin-right:auto;
 /* width: 600px;
  padding: 5px 20px 13px 20px;
  border-radius: 10px;
  background: #fff;
  background: -moz-linear-gradient(#fff, #999);
  background: -webkit-linear-gradient(#fff, #999);
  background: -o-linear-gradient(#fff, #999);*/

  padding-right: 15px;
  padding-left: 15px;


}

h1 {
  margin-inline-start: 0.35em;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
 .shots{
  padding-bottom: 60px;
 }



.footer{
  position: absolute;
    bottom: 0;
    width: 100%;
    height: 145px;
    background-color: #222222;
}

.text_st {
  color: #FFF;  
  text-align: center;
  line-height: 30px;
}

a {
  color: #337ab7;
  text-decoration: none;
}


.trans_div {
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.8);
  border-radius: 12px;
  background: none repeat scroll 0% 0% rgba(100, 100, 100, 0.4);
  padding-bottom: 30px;
  margin: 10px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #FFF;
}

.main_tran {
  padding-top: 2em;
  padding-bottom: 2em;
  margin-bottom: 0px;
  background-position: 0% 25%;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  text-shadow: black 0.1em 0.1em 0.1em;
}

.maincon{
  width: 100%;
  background-image: url("../public/images/nature2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.screencon{
  width: 100%;
  display: inline-block;
  padding-bottom: 173px;
}

.pich{
  font-size: 17px; /* 53*/
  margin-inline-start: unset;
}

.picp{
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200;
  line-height: 1.4;
}

.shot_div{
  display:block;
}

.shot_div>img, .shot_div a>img{
  display: block;
    max-width: 100%;
    height: auto;
    margin:auto;
}

.caption{
  margin-left:4%;
  margin-right:4%;
}

.pic-col{
  width: 100%;
  float:left;
  text-align: center;
}



@media screen and (min-width: 185px){
  .pich{
    font-size: 27px;
    margin-inline-start: unset;
  }
}

@media screen and (min-width: 255px){
  .footer{
    height: 95px;
  }

  .pich{
    font-size: 37px;
    margin-inline-start: 0.35em;
  }

}

@media screen and (min-width: 490px){
  .footer{
    height: 60px;
  }
}

@media screen and (min-width: 630px){
  .pic-col{
    width: 33.33%;
    float:left;
    text-align: center;
  }
  .partcontent{
    width: 580px;
  }
  .pich{
    font-size: 63px;
  }  
  .picp{
    margin-bottom: 15px;
    font-size: 25px;
  }
  .footer{
    height: 60px;
  }
  .screencon{
    padding-bottom:120px;
  }
}

@media screen and (min-width: 790px){
  .pic-col{
    width: 25%;
    float:left;
    text-align: center;
  }

  .pic-col2{
    width: 50%;
    float:left;
    text-align: center;
  }
  .partcontent{
    width: 740px;
  }
  .pich{
    font-size: 63px;
  }  
  .picp{
    margin-bottom: 15px;
    font-size: 25px;
  }
  .screencon{
    padding-bottom:120px;
  }
}

@media screen and (min-width: 980px){
  .pic-col{
    width: 20%;
    float:left;
    text-align: center;
  }
  .partcontent{
    width: 930px;
  }
  .pich{
    font-size: 63px;
  }  
  .picp{
    margin-bottom: 15px;
    font-size: 25px;
  }
  .screencon{
    padding-bottom:120px;
  }
}

@media screen and (min-width: 1100px){
  .pic-col{
    width: 16.66%;
    float:left;
    text-align: center;
  }
  .partcontent{
    width: 1050px;
  }
  .pich{
    font-size: 63px;
  }  
  .picp{
    margin-bottom: 15px;
    font-size: 25px;
  }
  .screencon{
    padding-bottom:120px;
  }
}

.googlelogoim{
  width: 190px;
  height: 73px;
}






























